import { injectable } from "inversify";
import { UserBrand as Brand } from "user/models/brand";
import { Api } from "shared/api/index";
import container from "shared/inversify.config";

@injectable()
export class BrandApi {
  axios = container.get(Api);

  async getBrands(): Promise<Brand[]> {
    const { data } = await this.axios.get<{data: Brand[]}>("/brands");
    return data;
  }
}
