import Axios, { AxiosResponse } from "axios";
import { injectable } from "inversify";

@injectable()
export class Api {
  axios: typeof Axios;
  constructor() {
    const _axios = Axios;
    _axios.defaults.headers.common["X-CSRF-Token"] = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute("content");

    _axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // switch (error.response.status) {
        //   case 500:
        //     break;
        //   case 401:
        //     alert("not authenticated");
        //     break;
        // }
        console.error(error);
        return Promise.reject(error);
      },
    );
    this.axios = _axios;
  }

  async get<T>(route: string): Promise<T> {
    return Axios.get(route) as Promise<T>;
  }
  async post<T>(route: string, params?: any): Promise<T> {
    return Axios.post(route, params) as Promise<T>;
  }
  async put<T>(route: string, params?: any): Promise<T> {
    return Axios.put(route, params) as Promise<T>;
  }
  async patch<T>(route: string, params?: any): Promise<T> {
    return Axios.patch(route, params) as Promise<T>;
  }
  async destroy<T>(route: string): Promise<T> {
    return Axios.delete(route) as Promise<T>;
  }
}
