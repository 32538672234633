export enum RepeatType {
  Every = "Every",
  OnDay = "OnDay",
}

export enum RepeatEveryType {
  DAYS = "days",
  WEEKS = "weeks",
  MONTHS = "months",
}

export interface Repeatable {
  type?: RepeatType | null;
  repeat_interval_type?: RepeatEveryType | null;
  repeat_interval_value: number | null;
  repeat_day_of_week?: number[];
  repeat_end_time?: string | null;
  isEnds?: boolean;
}
