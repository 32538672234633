import container from "shared/inversify.config";

import { defineStore } from "pinia";
import { ref, shallowReadonly } from "vue";
import { BrandApi } from "user/api/brand";
import { UserBrand as Brand } from "user/models/brand";

export const useBrandStore = defineStore("brand", () => {
  const brands = ref<Brand[]>();
  const selectedBrand = ref<Brand>();

  function $reset() {
    brands.value = undefined;
  }

  async function fetchBrands() {
    const result = await container.get(BrandApi).getBrands();
    brands.value = result;
  }

  function getBrandsById(id: number) {
    return brands.value?.find((el) => el.brand_id == id);
  }

  function getBrandsByIdentifier(id: string) {
    return brands.value?.find((el) => el.identifier == id);
  }

  function setSelectBrand(brand: Brand) {
    selectedBrand.value = brand;
  }
  function resetSelectBrand() {
    selectedBrand.value = undefined;
  }

  return {
    $reset,
    brands: shallowReadonly(brands),
    selectedBrand: shallowReadonly(selectedBrand),
    fetchBrands,
    getBrandsById,
    getBrandsByIdentifier,
    setSelectBrand,
    resetSelectBrand,
  };
});
